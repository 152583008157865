frappe.provide("frappe.ui");
frappe.provide("frappe.web_form_contract");
import EventEmitterMixin from '../../../../frappe/frappe/public/js/frappe/event_emitter';
import CollectPayment from './collect_payment_import';

export default class WebFormContract extends frappe.ui.FieldGroup {
    get submitButtons() {
        return this.buttons.primary;
    }

    get tourSteps() {
        const fields = this.fields_dict;
        const position = 'bottom';
        const acknowledgementFieldText = {
            continuation_acknowledgement: '<div class="mb-2">Enter your initials to indicate that you acknowledge and agree to the continuation terms.</div><div>All initials must be identical.</div>',
            non_cancelable_acknowledgement: '<div class="mb-2">Enter your initials to indicate that you understand this agreement is non-cancellable by you.</div><div>All initials must be identical.</div>',
            exclusivity_acknowledgement: '<div class="mb-2">Enter your initials to indicate that you acknowledge and agree to the exclusivity terms.</div><div>All initials must be identical.</div>',
        };
        var acknowledgementFields = [];
        // for (const el of document.getElementsByClassName('acknowledgement')) {
        const els = document.getElementsByClassName('acknowledgement');
        for (var i = 0; i < els.length; i++) {
            const el = els[i];
            const fieldname = el.dataset.fieldname;
            let prevButton = [];
            if (i > 0) {
                prevButton.push({text: 'Previous', classes: 'btn btn-secondary btn-sm', action: this.tour.back, secondary: true});
            }
            const buttons = [
                {text: 'Hide', classes: 'btn btn-secondary btn-sm', action: this.tour.cancel, secondary: true},
                ...prevButton,
                {text: 'Next', classes: 'btn btn-primary btn-sm', action: this.tour.next},
            ];
            acknowledgementFields.push({
                text: acknowledgementFieldText[fieldname],
                attachTo: {element: fields[fieldname].input, on: position},
                buttons,
                popperOptions: {modifiers: [{name: "focusAfterRender", enabled: false}]},
            });
        }
        return [
            ...acknowledgementFields,
            {
                text: '<div class="mb-2">Sign the agreement by drawing in this field.</div><div>If you are using a cell phone or touchscreen, you can sign with your finger or stylus. Otherwise, use a mouse.<div>',
                attachTo: {element: fields.signature.wrapper.querySelector('.signature-field'), on: position},
                buttons: [
                    {text: 'Hide', classes: 'btn btn-secondary btn-sm', action: this.tour.cancel, secondary: true},
                    {text: 'Previous', classes: 'btn btn-secondary btn-sm', action: this.tour.back, secondary: true},
                    {text: 'Next', classes: 'btn btn-primary btn-sm', action: this.tour.next},
                ],
            }, {
                text: 'Enter your full name.',
                attachTo: {element: fields.full_name.input, on: position},
                buttons: [
                    {text: 'Hide', classes: 'btn btn-secondary btn-sm', action: this.tour.cancel, secondary: true},
                    {text: 'Previous', classes: 'btn btn-secondary btn-sm', action: this.tour.back, secondary: true},
                    {text: 'Next', classes: 'btn btn-primary btn-sm', action: this.tour.next},
                ],
                popperOptions: {modifiers: [{name: "focusAfterRender", enabled: false}]},
            }, {
                text: 'Use this button submit this agreement.',
                attachTo: {element: document.querySelector('.web-form-actions button:last-child'), on: position},
                buttons: [
                    {text: 'Hide', classes: 'btn btn-secondary btn-sm', action: this.tour.cancel, secondary: true},
                    {text: 'Previous', classes: 'btn btn-secondary btn-sm', action: this.tour.back, secondary: true},
                    {text: 'Close', classes: 'btn btn-primary btn-sm', action: this.tour.complete},
                ],
            },

        ];
    }

    constructor(opts) {
        super();
        this.buttons = {};
        Object.assign(this, opts);
        frappe.web_form = this;
        frappe.web_form.events = {};
        Object.assign(frappe.web_form.events, EventEmitterMixin);
        this.current_section = 0;
        this.buttonAdded = false;

        this.contractMethodPath = `dividers.www.starstix_contract${(this.demo) ? '.demo' : ''}.index`;
    }

    prepare(web_form_fields, doc, paymentOption) {
        this.fields = web_form_fields;
        this.doc = doc;
        this.paymentOption = paymentOption;
    }

    make(allow_edit) {
        super.make();
        this.set_sections();
        this.set_field_values();
        this.setup_listeners();
        if (this.introduction_text) this.set_form_description(this.introduction_text);
        this.setup_primary_action(allow_edit);
        this.enableDisableSubmit()
        $(".link-btn").remove();
        $(".tooltip-content").remove();

        // webform client script
        frappe.init_client_script && frappe.init_client_script();
        frappe.web_form.events.trigger('after_load');
        this.after_load && this.after_load();
        const shepherdAssets = [
            '/assets/dividers/node_modules/shepherd.js/dist/css/shepherd.css',
            '/assets/dividers/node_modules/shepherd.js/dist/js/shepherd.min.js'
        ];
        frappe.require(shepherdAssets, () => {this.setupTour();});
    }

    setupTour() {
        if (!this.canSign) return;
        if (this.doc.docstatus < 1 || !this.doc.signature) {
            // A set of instructions to display
            const instructionTour = new Shepherd.Tour({
                useModalOverlay: true,
                defaultStepOptions: {classes: 'shadow-md bg-purple-dark'},
            });
            instructionTour.addStep({
                title: 'Guided Entry',
                text: `<div><strong>Guided Entry</strong> is available to walk you through the required fields on your paperwork.</div>
                      <ul class="mt-2 mb-2">
                        <li class="mb-1">To start the guide, close this dialog and use the highlighted "<strong>Guided Entry</strong>" button at the top of the page.</li>
                        <li class="mb-1">Use the buttons at the bottom of the guide to navigate between fields and hide the guide.</li>
                        <li>To resume your place after hiding <strong>Guided Entry</strong> use the "<strong>Guided Entry</strong>" button.</li>
                      </ul>
                      <div>To begin reviewing your paperwork, use the <strong>Close</strong> button below.</div>`,
                attachTo: {element: document.querySelector('.page-header-actions-block .web-form-actions button:first-child'), on: 'bottom'},
                buttons: [{text: 'Close', classes: 'btn btn-primary btn-sm', action: instructionTour.cancel}],
                classes: 'instruction-tour',
            });
            instructionTour.start();
        }

        // The actual tour
        this.tour = new Shepherd.Tour({
            useModalOverlay: false,
            defaultStepOptions: {
                classes: 'shadow-md bg-purple-dark',
                scrollTo: {behavior: 'smooth', block: 'center'},
                scrollToHandler: (e) => {
                    // blur events can interfere with scrollIntoView.
                    // Technically the blur event has allready occured, because
                    // document.activeElement is no longer the INPUT field,
                    // but we still need to give the browser a chance to
                    // "catch up" to its own internal state. Run the scroll
                    // handler after a delay to allow the so it triggers
                    // properly.
                    setTimeout(() => e.scrollIntoView({behavior: 'smooth', block: 'center'}), 100);
                }
            },
        });
        for (const step of this.tourSteps) {
            this.tour.addStep(step);
        }
    }

    showTour() {
        const step = this.tour.getCurrentStep();
        if (!step) {
            this.tour.start();
        } else {
            this.tour.show(step.id);
        }
    }

    on(fieldname, handler) {
        let field = this.fields_dict[fieldname];
        field.df.change = () => {
            handler(field, field.value);
            this.enableDisableSubmit();
        };
    }

    enableDisableSubmit() {
        if (!this.submitButtons) return;
        for (let button of this.submitButtons) {
            if (this.validateWebForm(false)) {
                button.classList.remove('disabled');
            } else {
                button.classList.add('disabled');
            }
        }
    }

    setup_listeners() {
        // Event listener for triggering Save/Next button for Multi Step Forms
        // Do not use `on` event here since that can be used by user which will render this function useless
        // setTimeout has 200ms delay so that all the base_control triggers for the fields have been run
        let me = this;

        if (!me.is_multi_step_form) {
            return;
        }

        for (let field of $(".input-with-feedback")) {
            $(field).change((e) => {
                setTimeout(() => {
                    e.stopPropagation();
                    me.toggle_buttons();
                }, 200);
            });
        }
    }

    set_sections() {
        if (this.sections.length) return;

        this.sections = $(`.form-section`);
    }

    set_field_values() {
        if (this.doc.name) this.set_values(this.doc);
        else return;
    }

    set_default_values() {
        let values = frappe.utils.get_query_params();
        delete values.new;
        this.set_values(values);
    }

    set_form_description(intro) {
        let intro_wrapper = document.getElementById('introduction');
        intro_wrapper.innerHTML = intro;
    }

    add_button(name, type, action, wrapper_class=".web-form-actions") {
        const button = document.createElement("button");
        button.classList.add("btn", "btn-" + type, "btn-md");
        if (this.buttonAdded) {
            button.classList.add('ml-2');
        }
        button.innerHTML = name;
        button.onclick = action;
        document.querySelector(wrapper_class).appendChild(button);

        if (!this.buttons[type]) this.buttons[type] = [];
        this.buttons[type].push(button);
        this.buttonAdded = true;
    }

    add_button_to_footer(name, type, action) {
        this.add_button(name, type, action, '.web-form-footer');
    }

    add_button_to_header(name, type, action) {
        this.add_button(name, type, action, '.web-form-actions');
    }

    setup_primary_action(allow_edit) {
        console.debug('paymentOption', this.paymentOption);
        const buttonLabel = () => {
            switch(this.paymentOption.name) {
                case 'pay_now':
                    return 'Pay And Submit';
                case 'zero_down':
                    return 'Add Payment Method And Submit';
                case 'delayed_payment':
                    if (this.paymentOption.options.payment_date) {
                        return 'Add Payment Method And Submit';
                    }
            }
            return 'Submit';
        };
        const setupStickyHeaderActions = () => {
            // always keep the header action buttons in view
            var scrollPosition = window.scrollY;
            var stickyBtn = document.querySelector('.web-form-actions');
            window.addEventListener('scroll', () => {
                scrollPosition = window.scrollY;
                if (scrollPosition >= 200) {
                    stickyBtn.classList.add('sticky-header-actions');
                } else {
                    stickyBtn.classList.remove('sticky-header-actions');
                }
            });
        };

        if (allow_edit && (this.doc.docstatus < 1 || !this.doc.signature)) {
            this.add_button_to_header(
                this.button_label || __('Guided Entry', null, "Button in web form"),
                "light",
                () => {this.showTour()});
        }

        if (this.allow_print) {
            this.add_button_to_header(frappe.utils.icon('printer'), "light", () => this.print());
        }

        if (allow_edit) {
            this.add_button_to_header(
                this.button_label || __(buttonLabel(), null, "Button in web form"),
                "primary",
                () => this.save());
        }

        setupStickyHeaderActions();
    }

    toggle_buttons() {
        for (let idx = this.current_section; idx < this.sections.length; idx++) {
            if (this.is_next_section_empty(idx)) {
                this.show_save_and_hide_next_button();
            } else {
                this.show_next_and_hide_save_button();
                break;
            }
        }
    }

    is_next_section_empty(section) {
        if (section + 1 > this.sections.length) return true;

        let _section = $(`.form-section:eq(${section + 1})`);
        let visible_controls = _section.find(".frappe-control:not(.hide-control)");

        return !visible_controls.length ? true : false;
    }

    is_previous_section_empty(section) {
        if (section - 1 > this.sections.length) return true;

        let _section = $(`.form-section:eq(${section - 1})`);
        let visible_controls = _section.find(".frappe-control:not(.hide-control)");

        return !visible_controls.length ? true : false;
    }

    show_save_and_hide_next_button() {
        $('.btn-next').hide();
        $('.web-form-footer').show();
    }

    show_next_and_hide_save_button() {
        $('.btn-next').show();
        $('.web-form-footer').hide();
    }

    show_section() {
        $(`.form-section:eq(${this.current_section})`).show();
    }

    hide_sections() {
        for (let idx=0; idx < this.sections.length; idx++) {
            if (idx !== this.current_section) {
                $(`.form-section:eq(${idx})`).hide();
            }
        }
    }

    print() {
        const printFormat = (this.demo) ? 'Standard eContract Demo' : 'Standard eContract';
        
        // if the "Get PDF" link pulls the wrong PDF, set the
        // print format you would like to see as the default under "Customize"
        const printUrl = `/printview?doctype=${this.doc_type}&name=${this.doc.name}&format=${printFormat}`;
        window.open(printUrl, '_blank');
    }

    validateWebForm(reportErrors=true) {
        clearFormErrors(this.fields_dict);

        let errors = [];
        let invalid_values = [];
        var fieldName;
        var field;

        // collect initial fields
        const initialFields = Object.keys(this.fields_dict)
        .filter(key => key.endsWith('acknowledgement'))
        .reduce((obj, key) => {
            obj[key] = this.fields_dict[key];
            return obj;
        }, {});

        let uniqueInitials = new Set();
        for ([fieldName, field] of Object.entries(initialFields)) {
            if (!field.get_value) continue;
            let value = field.get_value();
            if (!(typeof value === 'string' && value !== '')) continue;
            uniqueInitials.add(value.toUpperCase().trim());
        }
        for ([fieldName, field] of Object.entries(this.fields_dict)) {
            if (!field.get_value) continue;

            let value = field.get_value();
            if (field.df.reqd && is_null(typeof value === 'string' ? strip_html(value) : value)) {
                errors.push(__(field.df.label));
                highlightFormError(field);
            }

            if (field.df.reqd && field.df.fieldtype === 'Text Editor' && is_null(strip_html(cstr(value)))) {
                errors.push(__(field.df.label));
                highlightFormError(field);
            }

            if (field.df.invalid) {
                invalid_values.push(__(field.df.label));
                highlightFormError(field);
            }
        }

        let message = '';

        // initials are invalid only if there is more than one entered - otherwise it isn't invalid, it is missing
        const invalidInitials = uniqueInitials.size > 1
        if (invalidInitials) {
            message += __('All initials must be the same:') + '<br><br><ul><li>' + Array.from(uniqueInitials).join('<li>') + '</ul>';
            highlightFormError(initialFields);
        }

        if (invalid_values.length) {
            message += __('Invalid values for fields:') + '<br><br><ul><li>' + invalid_values.join('<li>') + '</ul>';
        }

        if (errors.length) {
            message += __('Mandatory fields required:') + '<br><br><ul><li>' + errors.join('<li>') + '</ul>';
        }

        if (reportErrors && (invalid_values.length || errors.length || invalidInitials)) {
            frappe.msgprint({
                title: __('Error'),
                message: message,
                indicator: 'orange'
            });
        }

        return !(errors.length || invalid_values.length || invalidInitials);
    }

    save() {
        showErrors(frappe.web_form.wrapper);

        const doNotCollectPayment = (
            ['agency', 'third_party'].includes(this.paymentOption.name) ||
            (
                this.paymentOption.name === 'delayed_payment' &&
                this.paymentOption.options.payment_option === 'Payment information to follow'
            )
        );

        if (!this.validateWebForm(true)) {
            return;
        }
        this.tour.cancel();

        // get_values may throw a validation error of it's own
        let doc_values = super.get_values();

        if (!doc_values) return;

        if (window.saving) return;

        let value;
        for (const key in doc_values) {
            value = doc_values[key]
            if (typeof value === 'string') {
                doc_values[key] = value.trim();
            }
        }

        Object.assign(this.doc, doc_values);
        this.doc.doctype = this.doc_type;
        this.doc.web_form_name = this.name;

        // Save
        window.saving = true;
        frappe.form_dirty = false;

        if (doNotCollectPayment) {
            this.submit_signature();
        } else {
            const submitSignatureCB = (paymentResponse) => {
                this.submit_signature(JSON.stringify(paymentResponse));
            }
            const paymentPopup = new CollectPayment(
                this.demo, this.doc, this.paymentOption, this.contractMethodPath,
                submitSignatureCB);
            paymentPopup.display();
        }
    }

    submit_signature(confirmResponse='') {
        window.saving = true;  // we're still saving

        // prefer this to frappe.call({freeze:true}),
        // because freeze_message doesn't work in webform
        frappe.freeze('Submitting your signature...');
        frappe.call({
            type: 'POST',
            method: `${this.contractMethodPath}.save_signature`,
            args: {
                ...this.doc,
                payment_data: confirmResponse,
            },
            callback: (r) => {
                this.handle_success(r);
                this.after_save && this.after_save();
            },
            always: function() {
                window.saving = false;
                frappe.unfreeze();
            },
        });
    }

    handle_success(data) {
        if (data) {
            // Only show success if the contract was returned as data.
            // Otherwise, an error should already be displaying on the screen.
            const success_dialog = frappe.msgprint({
                title: __("Signed Successfully"),
                message: __("Your signature has been submitted"),
                clear: true,
                indicator: false,
            });

            frappe.msg_dialog.custom_onhide = () => {
                if ('scrollRestoration' in history) {
                    // make the browser forget the scroll position
                    history.scrollRestoration = 'manual';
                }
                window.location.reload()
            };
            success_dialog.show();
        }
    }
}