import WebFormContract from './webform_contract_import'


window.showErrors = (parentEl) => parentEl.addClass('show-errors');

window.clearFormErrors = (fields_dict) => {
    for (const field of Object.values(fields_dict)) {
        field.$wrapper?.toggleClass('has-error', false);
    }
}

window.highlightFormError = (field) => {
    if ('$wrapper' in field) {
        field.$wrapper.toggleClass('has-error', true);
    } else {
        // iterate through a field_dict
        for (const f of Object.values(field)) {
            highlightFormError(f);
        }
    }
}


// fix a bug in the DateTime validation
class ControlDatetime extends frappe.ui.form.ControlDatetime {
    validate(value) {
        value = moment(value).format('YYYY-MM-DD HH:mm:ss')
        if(value && !frappe.datetime.validate(value)) {
            let sysdefaults = frappe.sys_defaults;
            let date_format = sysdefaults && sysdefaults.date_format
                ? sysdefaults.date_format : 'YYYY-MM-DD HH:mm:ss';
            frappe.msgprint(__("Date {0} must be in format: {1}", [value, date_format]));
            return '';
        }
        return value;
    }
}
frappe.ui.form.ControlDatetime = ControlDatetime;

class FieldGroup extends frappe.ui.FieldGroup {
    set_value(key, val) {
        return new Promise(resolve => {
            var f = this.fields_dict[key];
            if(f) {
                f.set_value(val).then(() => {
                    f.set_input(val);
                    this.refresh_dependency();
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }
}
frappe.ui.FieldGroup = FieldGroup


frappe.ready(function() {
    var ws;
    let query_params = frappe.utils.get_query_params();
    let wrapper = $(".web-form-wrapper");
    let eContractDoctype = wrapper.data('e-contract-doctype');
    let eContractName = wrapper.data('e-contract-name');
    let paymentErrorMessage = wrapper.data('payment-error-message');
    let demo = wrapper.data('e-contract-demo');
    let canSign = wrapper.data('e-contract-signer');

    var contractMethodPath = `dividers.www.starstix_contract${(demo) ? '.demo' : ''}.index`;
    var econtractDoctypeForPath = (demo) ? 'starstix_econtract_demo' : 'starstix_econtract';
    var econtractDoctypePath = `dividers.sales_force.doctype.${econtractDoctypeForPath}.${econtractDoctypeForPath}`;

    const contractPubPath = (demo) ? 'starstix_econtract_webform_demo' : 'starstix_econtract_webform';
    const pub_id = `${contractPubPath}-${eContractName}`;


    if (Object.keys(query_params).length > 1) {
        const args = frappe.utils.get_url_from_dict({name: eContractName});
        location.replace(`${location.pathname}?${args}`);
    }

    if ((eContractDoctype && eContractName)) {
        showForm(eContractDoctype, eContractName);
    }

    document.querySelector("body").style.display = "block";

    if (frappe.web_form) {
        frappe.web_form.after_load = () => {
            if (frappe.web_form.doc.docstatus === 1 && (frappe.web_form.doc.signature && !canSign)) {
                // Contract can only be displayed and printed
                if ('scrollRestoration' in history) {
                    // restore the browser's scroll position memory
                    history.scrollRestoration = 'auto';
                }
                frappe.web_form.set_form_description(`
                    <div class="form-message cyan">
                        <div>Your signature has been successfully submitted. Thank you for for your business!</div>
                        <div>You can print or download a copy of your contract using the print button on the right.</div>
                    </div>
                `);
            } else {
                setupOn();
                setTimeout(() => placeAcknowledgementFields(), 400);
            }
        };

        frappe.web_form.validate = (d) => {
            let prime;
            let val;
            for (const [fn, f] of Object.entries(frappe.web_form.fields_dict)) {
                if (['Section Break'].includes(f.df.fieldtype)
                    || f.wrapper.classList.contains('hide-control')) continue;
                if (fn.endsWith('_acknowledgement')) {
                    val = (frappe.web_form.doc[fn])
                        ? frappe.web_form.doc[fn].trim()
                        : 'no user value';
                    if (!prime) {
                        prime = val;
                    } else if (prime !== val) {
                        frappe.msgprint(
                            `Initials "${prime}" and "${val}" are different;
                            please use the same initials in all initials fields`);
                        return false;
                    }
                }
            }
            return true;
        }
    } else {
        frappe.throw('You do not have permission to view this URL');
    }

    function showForm() {
        let web_form = new WebFormContract({
            parent        : wrapper,
            web_form_name : eContractName,
            doc_type      : eContractDoctype,
            demo          : demo,
            canSign       : canSign,
        });

        get_data().then(r => {
            const data = setup_fields(r.message);
            let doc_fields = data.doc_fields;
            let payment_option = data.payment_option;

            let doc = data.doc;
            if (doc.real_time_field_values) {
                Object.assign(doc, JSON.parse(doc.real_time_field_values));
            }
            web_form.prepare(doc_fields, doc, payment_option);
            web_form.introduction_text = `Contract ID partial: <strong>${doc.name.slice(-5)}</strong>`;
            web_form.allow_print = true;
            // set doctype to 'Web Form' so that enables set_dependant_property()
            // will work
            web_form.doctype = 'Web Form';
            web_form.make(data.allow_edit);
            web_form.set_default_values();

            const signatureImage = document.querySelector('.signature-display img');
            if (signatureImage) {
                signatureImage.setAttribute('draggable', false);
            }

            if (!demo && !data.allow_edit && !canSign) {
                console.error(`demo=${demo}, allow_edit=${data.allow_edit}, canSign=${canSign}`);
                frappe.msgprint('Contract opened in read-only mode', 'Notice');
            }

            if (doc.docstatus < 1) {
                // don't bother setting up socketID if the signature is
                // submitted
                setupSocketIO();
                ws = dividers.utils.setupWebSocket();
                setAliveIndicator();
            }
        });

        function build_doc(form_data) {
            let doc = {};
            form_data.doc_fields.forEach(df => {
                if (df.default) return doc[df.fieldname] = df.default;
            });
            return doc;
        }

        function get_data() {
            return frappe.call({
                type: 'POST',
                method: `${contractMethodPath}.get_econtract_data`,
                args: {
                    econtract_name: eContractName,
                },
                freeze: true,
                freeze_message: 'fetching contract...',
            });
        }

        function setup_fields(form_data) {
            form_data.doc_fields.map(df => {
                df.is_web_form = true;
                if (df.fieldtype === "Table") {
                    df.get_data = () => {
                        let data = [];
                        if (form_data.doc) {
                            data = form_data.doc[df.fieldname];
                        }
                        return data;
                    };

                    df.fields = form_data[df.fieldname];
                    $.each(df.fields || [], function(_i, field) {
                        if (field.fieldtype === "Link") {
                            field.only_select = true;
                        }
                        field.is_web_form = true;
                    });

                    if (df.fieldtype === "Attach") {
                        df.is_private = true;
                    }

                    delete df.parent;
                    delete df.parentfield;
                    delete df.parenttype;
                    delete df.doctype;

                    return df;
                }
                if (df.fieldtype === "Link") {
                    df.only_select = true;
                }
                if (["Attach", "Attach Image"].includes(df.fieldtype)) {
                    if (typeof df.options !== "object") {
                        df.options = {};
                    }
                    df.options.disable_file_browser = true;
                }
            });

            return form_data;
        }
    };

    /**
     * Send a keep_alive signal so the sales rep knows the advertiser
     * is reviewing the document
     */
    function setAliveIndicator() {
        const payload = {
            doctype: frappe.web_form.doc_type,
            docname: frappe.web_form.doc.name,
            event: 'keep_alive',
        }
        if (ws.readyState > ws.OPEN) {
            // attempt to reconnect
            console.log('attempting to reconnect')
            ws = dividers.utils.setupWebSocket();
            setTimeout(() => {
                setAliveIndicator();
            }, 500);
        }
        if (ws.readyState < ws.OPEN) {
            setTimeout(() => {
                console.debug('waiting for websocket connection');
                setAliveIndicator();
            }, 500);
            return;
        }
        ws.send(JSON.stringify(payload));
        setTimeout(() => {
            console.debug('keep_alive');
            setAliveIndicator();
        }, 3000);
    };

    /**
     * Setup socketIO to listen for instructions for the client
     */
    function setupSocketIO() {
        frappe.realtime.on(
            pub_id,
            ({msg, type}) => {
                console.debug('realtime data', type, msg);
                switch(type) {
                    case 'alert':
                        frappe.show_alert({message: msg, indicator: 'yellow'}, 10);
                        break;
                    case 'refresh':
                        var d = new frappe.ui.Dialog({
                            title: __("Confirm"),
                            primary_action_label: __("Reload Page"),
                            primary_action: () => {
                                location.reload();
                                d.hide();
                            },
                            secondary_action_label: __("No"),
                            secondary_action: () => d.hide(),
                        });

                        d.$body.append(`<p class="frappe-confirm-message">${msg}</p>`);
                        d.show();

                        // flag, used to bind "okay" on enter
                        d.confirm_dialog = true;

                        return d;
                    case 'notice':
                        frappe.msgprint(msg, 'Notice');
                }
            });
        console.info(`subscribed to ${pub_id}`);
    };

    function setupOn() {
        const realtimeFields = [
            'continuation_acknowledgement',
            'non_cancelable_acknowledgement',
            'exclusivity_acknowledgement',
            'signature',
            'full_name',
        ];
        const recordChange = frappe.utils.debounce((fieldname, newValue) => {
            const getRealtimeValues = () => {
                const rtValues = {};
                for (const fieldname of realtimeFields) {
                    rtValues[fieldname] = frappe.web_form.doc[fieldname] || '';
                }
                return rtValues;
            }

            frappe.call({
                method: `${econtractDoctypePath}.realtime_field_update`,
                args: {dn: eContractName, realtime_values: getRealtimeValues()},
            });
        }, 200, false);

        for (const field of realtimeFields) {
            if (frappe.web_form.has_field(field)) {
                frappe.web_form.on(field, recordChange);
            }
        }
    };

    function placeAcknowledgementFields() {
        for (const [fn, f] of Object.entries(frappe.web_form.fields_dict)) {
            if (fn.endsWith('_acknowledgement')) {
                const placeEl = document.querySelector(`.acknowledgement[data-fieldname=${fn}`);
                if (frappe.web_form.doc.docstatus == 1 && !canSign) {
                    f.wrapper.remove();
                } else if (placeEl) {
                    placeEl.appendChild(f.wrapper);
                }
            }
        }
    };
});
