import AuthorizeNetPaymentPlugin from './payment_plugins/authorize_net';

export default class CollectPayment {
     constructor(demo, doc, paymentOption, contractMethodPath, submitSignatureCB) {
        this.demo = demo;
        this.doc = doc;
        this.paymentOption = paymentOption;
        this.contractMethodPath = contractMethodPath;
        this.submitSignatureCB = submitSignatureCB;
        this.elements = null;
        this.payFormMsgBox = null;
        this.paymentInfo = {};
        this.billingDetails = null;
        this.fakeFooterButtons = `
            <div class="modal-footer pt-4" style="padding: -(var(--padding-md)) -(var(--padding-lg))">
                <button type="button" class="btn btn-default btn-sm btn-modal-close" data-dismiss="modal">
                    <span class="hidden-xs">Close</span>
                </button>
                <div id="modal-body-buttons">
                    <button type="button" class="btn btn-sm btn-secondary btn-modal-back mr-2">Back</button>
                    <button type="button" class="btn btn-sm btn-primary btn-modal-pay disabled" id="pay-now-button">${this.payButtonLabel}</button>
                </div>
            </div>`;
        this.delayedUnlockContractForm = true;
    }
 
    get payButton() {
        return document.getElementById('pay-now-button');
    }

    get payButtonLabel() {
        switch(this.paymentOption.name) {
        case 'pay_now':
            return 'Pay And Submit';
        case 'zero_down':
            return 'Add Payment Method And Submit';
        case 'delayed_payment':
            if (this.paymentOption.options.payment_date) {
                return 'Add Payment Method And Submit';
            }
        }
        return 'Pay Now';
    }

    payButtonIsDisabled() {
        return this.payButton.classList.contains('disabled');
    }

    disablePayButton() {
        this.payButton.classList.add('disabled');
    };

    enablePayButton() {
        this.payButton.classList.remove('disabled');
    };

    get countries() {
        return {
            'United States': 'US',
            'Canada': 'CA',
        }
    }

    get splitPaymentsNotice() {
        if (!this.paymentInfo.split || this.paymentInfo.split < 2) {
            return '';
        }
        return `<div class="mb-4">
            <div class="notice align-top">
                <div class="header">
                    Split Payments
                </div>
                <div>Today's deposit and all future payments will
                    be split evenly between the payment methods
                    provided.</div>
            </div>
            <div style="max-width:300px;margin:0 auto">
                ${this.paymentInfo.payment_table}
            </div>
        </div>
        `;
    }

    get paymentChargedOn() {
        console.debug('paymentOptions', this.paymentOption);

        const paymentOptions = this.paymentOption.options;
        let paymentDate = '';
        switch (this.paymentOption.name) {
            case 'pay_now':
                return '';
            case 'zero_down':
                paymentDate = frappe.datetime
                .str_to_obj(paymentOptions.first_installment_date)
                .toLocaleDateString('en-us', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
                break;
            case 'delayed_payment':
                paymentDate = frappe.datetime
                .str_to_obj(paymentOptions.payment_date)
                .toLocaleDateString('en-us', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
                break;
        }

        const first = (paymentOptions.split > 1)
            ? ' first'
            : '';

        if (paymentDate !== '') {
            return `<div class="payment-charge-date mb-6 form-message green">
                Your${first} payment will be charged on:<br><strong>${paymentDate}</strong>.
            </div>`;
        }
        return '';
    }

    /**
     * When the customer starts the payment flow, the contract is locked
     * to prevent changes. This unlocked the contract.
     */
    unlockContractForm(immediately=false) {
        if (immediately) {
            if (window.saving === true) return;
            frappe.call({
                type: 'POST',
                method: `${this.contractMethodPath}.unlock_econtract_form`,
                args: {starstix_econtract: this.doc.name},
            });
        } else {
            setTimeout(() => this.unlockContractForm(true), 500);
        }
    }

    display() {
        this.fetchContractPaymentInformation();
    }

    fetchContractPaymentInformation() {
        frappe.freeze(`
            Retrieving payment information...
            <div class='h-100 d-flex align-items-center justify-content-center'>
                <div class='spinner'>
                    ${frappe.utils.icon('setting-gear', 'xl')}
                </div>
            </div>
        `);
        var errorDuringFrappeCall = true;
        frappe.call({
            type           : 'POST',
            method         : `${this.contractMethodPath}.fetch_contract_deposit_payments`,
            args           : {econtract_name: this.doc.name},
            callback       : ({message: paymentInfo}) => {
                errorDuringFrappeCall = false;
                // console.debug('paymentInfo', paymentInfo);
                this.paymentInfo = paymentInfo;
                this.beginPaymentProcess();
            },
            always: r => {
                setTimeout(() => {
                    if (errorDuringFrappeCall) {
                        frappe.throw(`<div class="mb-4">
                                There was an error retriving payment
                                information.
                            </div>
                            <h5>What can I do?</h5>
                            <div>
                                Please try again later. If the problem
                                persists, please contact your
                                Sales Representative.
                            </div>`);
                    }
                }, 500);
                window.saving = false;
                frappe.unfreeze();
            }
        });
    }

    /**
     * Begin the payment process by requesting that the server initialize
     * the payment with the payment gateway and returning the necessary
     * information to continue the process here.
     */
    beginPaymentProcess() {
        // Generate a payment request, then begin the payment collection process
        var errorDuringFrappeCall = true;
        frappe.call({
            type           : 'POST',
            method         : `${this.contractMethodPath}.initialize_deposit`,
            args           : {
                docname            : this.doc.name,
                webform_modified   : this.doc.modified,
                split_payment_form : false,
            },
            freeze         : true,
            freeze_message : 'Preparing secure payment...',
            callback       : ({message: response}) => {
                errorDuringFrappeCall = false;
                if (response.error) {
                    frappe.throw(response.error);
                }

                const gateway = response.gateway;
                const r = response.response;
                
                switch (gateway) {
                case 'Authorize.Net':
                    this.merchantCustomerId = response.merchant_customer_id;
                    this.paymentPageType = response.type;
                    this.authorizeNetToken = r.token;
                    this.formPostUrl = r.formPostUrl;
                    Object.assign(this, AuthorizeNetPaymentPlugin);
                    break;
                // case 'anotherGateway':
                //     if (r.error) {frappe.throw(r.error);}
                //     Object.assign(this, anotherGatewayPaymentPlugin);
                //     break;
                default:
                    console.error(`Unknown payment gateway "${gateway}"`);
                    frappe.throw('Unknown payment gateway');
                }

                this.displayPaymentMsgBox();
                if (this.payOptsMsgBox) {
                    this.payOptsMsgBox.hide();
                }
            },
            always: r => {
                setTimeout(() => {
                    if (errorDuringFrappeCall) {
                        frappe.throw(`<div class="mb-4">
                                There was an error communicating with the
                                payment gateway.
                            </div>
                            <h5>What can I do?</h5>
                            <div>
                                Please try again later, or contact your
                                Sales Representative to arrange an alternate
                                form of payment.
                            </div>`);
                    }
                }, 500);
                window.saving = false;
            },
        });
    }

    /**
     * Display the payment form
     **/
    displayPaymentMsgBox() {
        this.payFormMsgBox = frappe.msgprint({
            title: 'Please Enter Your Payment Information',
            message: `
                <div id="payment-msgprint-container">
                    ${this.demoPaymentInstructions()}
                    ${this.splitPaymentsNotice}
                    ${this.paymentChargedOn}
                    ${this.paymentElement()}
                    ${this.displayFakeFooterButtons ? this.fakeFooterButtons : ''}
                </div>
                `,
            // Don't set up the primary_action and secondary_action here.
            // The secondary_action doesn't work at all, and adding the
            // button manually to the footer alters all message dialogs.
            // Instead, fake it in the message body
            // primary_action: {},
            // secondary_action: {},
            clear: true,
            indicator: false,
        });
        this.payFormMsgBox.wrapper.classList.add('modal-lg');
        this.payFormMsgBox.footer.hide();
        frappe.msg_dialog.custom_onhide = () => {
            window.saving = false;
            this.unlockContractForm();
        };

        if (!this.displayFakeFooterButtons) {
            dividers.waitForEl('#payment-msgprint-container')
            .then((el) => {
                this.postDisplayPaymentMsgBox();
            });
        } else {
            dividers.waitForEl('#modal-body-buttons')
            .then((el) => {
                this.postDisplayPaymentMsgBox();
                this.disablePayButton();

                // attach actions to the fake footer buttons
                const parentEl = el[0];
                const backAction = () => {
                    parentEl.removeEventListener('click', backAction);
                    parentEl.removeEventListener('click', payAction);
                    this.payFormMsgBox.hide();
                    window.saving = false;
                };
                const payAction = () => {
                    if (!this.payButtonIsDisabled()) {
                        parentEl.removeEventListener('click', payAction);
                        parentEl.removeEventListener('click', backAction);
                        this.confirmPayment(this.payFormMsgBox);
                    }
                };
                dividers.addEventForChild(parentEl, 'click', '#modal-body-buttons .btn-modal-back', backAction);
                dividers.addEventForChild(parentEl, 'click', '#modal-body-buttons .btn-modal-pay', payAction);
            });
        }

    };

    /**
     * Record a payment
     * 
     * @param {Object} transaction Response
     */
    recordPayment(transactResponse) {
        console.debug('transactResponse', transactResponse);
        this.submitContract(transactResponse);
    };
}